<template>
  <div>
    <Spinner v-if="loading === true" />
    <div
      v-if="loading === false"
      class="row"
    >
      <div class="col-md-6" />
      <div class="col-md-6 create-button">
        <button
          id="deleteMultiple"
          type="button"
          class="btn btn-outline-primary mb-2"
          @click="confirmDeleteText(deletedArry)"
        >
          Delete Selected Elements
        </button>
        <button
          type="button"
          class="btn btn-primary mb-2 ml-1"
          data-toggle="modal"
          data-target="#createModal"
        >
          Create
        </button>
        <create
          v-if="$router.currentRoute.name === 'rooms'"
          @refresh="renderTable()"
        />
      </div>
    </div>
    <basic-table
      v-if="loading === false"
      ref="table"
      :columns="columns"
      :values="rows"
      :actions-obj="actionsArray"
      @dynamicAction="handleActions($event)"
      @choosenObject="getEditedObject($event)"
      @deleteItems="removeItems($event)"
      @deletedMultipleIds="catchIds($event)"
      @changeStatus="handleChangeStatus($event)"
    />
    <edit
      v-if="$router.currentRoute.name === 'rooms'"
      :item="editedRoom"
      @refresh="renderTable()"
    />
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import roomsService from '@/services/roomsService'
import Spinner from '@/shared/loader/Spinner'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import BasicTable from '../../../shared/tables/BasicTable.vue'
import Create from './create.vue'
import Edit from './edit.vue'

export default {
  components: {
    Spinner,
    BRow,
    BCol,
    Create,
    Edit,
    BasicTable,
  },
  data() {
    return {
      status: { is_active: '' },
      UpdatedRoom: {
        title: '',
        description: '',
        sort: '',
        is_active: '',
        icon: undefined,
        anchorIcon: undefined,
      },
      loading: false,
      userId: '',
      actionsArray: [],
      editedRoom: '',
      roleId: '',
      columns: [
        {
          label: 'Name',
          field: 'title',
        },
        {
          label: 'Icon',
          field: 'icon',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Default?',
          field: 'is_default',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  created() {
    this.getRooms()
    this.actionsArray = []
  },
  methods: {
    getRooms() {
      this.loading = true
      roomsService.index().then(response => {
        this.rows = response.data.data
        this.loading = false
      })
    },
    handleActions(itemObj) {},
    getEditedObject(obj) {
      this.editedRoom = obj
    },
    catchIds(ids) {
      this.deletedArry = ids
    },
    removeItems(arr) {
      this.loading = true
      this.confirmDeleteText(arr)
    },
    confirmDeleteText(arr) {

      if (arr.length > 0) {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.loading = false
            roomsService.delete(arr.join()).then(response => {
              if (response && response.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Items have been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => {
                  this.renderTable()
                })
              }
            }).catch(error => {
              this.loading = false
              this.renderTable()
              // return Promise.reject(error)
            })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loading = false
          } else {
            this.loading = false
          }
        })
      }
    },
    handleChangeStatus(row) {
      if (row) {
        this.$swal({
          title: 'Are you sure of publishing this Room?',
          text: ' ',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, publish it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.status.is_active = row.item.is_active === 0 ? 1 : 0
            console.log(this.status)
            this.UpdatedRoom = {
              name: row.item.name,
              sku_id: row.item.skuId,
              capacity: row.item.capacity,
              intensity_level: row.item.intensityLevel,
              work: row.item.work,
              stop: row.item.stop,
              oil_consumption: row.item.oilConsumption,
              customizable: row.item.customizable,
              image: undefined,
              is_active: this.status.is_active,
            }
            roomsService.changeStatus(row.item.id, this.UpdatedRoom, { lang: 'en' })
              .then(response => {
                /* Reset Inputs */
                if (response && response.data.code === 200) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Room has been published successfully !',
                    },
                  })
                  this.renderTable()
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'CheckIcon',
                      variant: 'danger',
                      text: response.data.message,
                    },
                  })
                  this.renderTable()
                }
                /* Hide Spinner */
                this.loading = false
              })
              .catch(
                function (error) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'CheckIcon',
                      variant: 'Error',
                      text: error.message,
                    },
                  })
                  // return Promise.reject(error)
                },
              )
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loading = false
          } else {
            this.loading = false
          }
        })
      }
    },
    renderTable() {
      this.getRooms()
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
